import React from "react";
import cn from "classnames";
import s from "./Icon.module.scss";

import ArrowDiverge from "/public/svg/icons/arrow-diverge.svg";
import ArrowDown from "/public/svg/icons/arrow-down.svg";
import ArrowDownLeft from "/public/svg/icons/arrow-down-left.svg";
import ArrowDownRight from "/public/svg/icons/arrow-down-right.svg";
import ArrowUp from "/public/svg/icons/arrow-up.svg";
import ArrowUpRight from "/public/svg/icons/arrow-up-right.svg";
import ArrowExpand from "/public/svg/icons/arrow-expand.svg";
import CaretDown from "/public/svg/icons/caret-down.svg";
import Add from "/public/svg/icons/add.svg";
import Minus from "/public/svg/icons/minus.svg";
import CaretRight from "/public/svg/icons/caret-right.svg";
import CaretLeft from "/public/svg/icons/caret-left.svg";
import World from "/public/svg/icons/symbolicons-world.svg";
import Search from "/public/svg/icons/search.svg";
import MobileNavButton from "/public/svg/icons/mobile-nav-button.svg";
import Cancel from "/public/svg/icons/cancel.svg";
import Check from "/public/svg/icons/check.svg";
import Checkmark from "/public/svg/icons/checkmark.svg";
import FaceBook from "/public/svg/icons/facebook.svg";
import Instagram from "/public/svg/icons/instagram.svg";
import LinkedIn from "/public/svg/icons/linkedin.svg";
import Twitter from "/public/svg/icons/x.svg";
import YouTube from "/public/svg/icons/youtube.svg";
import Binary from "/public/svg/icons/binary.svg";
import Science from "/public/svg/icons/science.svg";
import Factory from "/public/svg/icons/factory.svg";
import Presentation from "/public/svg/icons/presentation.svg";
import Car from "/public/svg/icons/car.svg";
import Custom3dCube from "/public/svg/icons/custom-3d-cube.svg";
import CustomVR from "/public/svg/icons/custom-vr.svg";
import Computer from "/public/svg/icons/computer.svg";
import Ellipsis from "/public/svg/icons/ellipsis.svg";
import EllipsisFilled from "/public/svg/icons/ellipsis-filled.svg";
import EyeTracking from "/public/svg/icons/eye-tracking.svg";
import Settings from "/public/svg/icons/settings.svg";
import Users from "/public/svg/icons/users.svg";
import GameController from "/public/svg/icons/game-controller.svg";
import Power from "/public/svg/icons/power.svg";
import BookOpen from "/public/svg/icons/book-open.svg";
import LifeRing from "/public/svg/icons/life-ring.svg";
import Wave from "/public/svg/icons/wave.svg";
import GraphLine from "/public/svg/icons/graph-line.svg";
import Quote from "/public/svg/icons/quote.svg";
import Hierarchy from "/public/svg/icons/hierarchy.svg";
import UserCircle from "/public/svg/icons/user-circle.svg";
import CreditCard from "/public/svg/icons/credit-card.svg";
import FileBadge from "/public/svg/icons/file-badge.svg";
import FileDownload from "/public/svg/icons/file-download.svg";
import Location from "/public/svg/icons/location.svg";
import Chat from "/public/svg/icons/chat.svg";
import Wallet from "/public/svg/icons/wallet.svg";
import Clock from "/public/svg/icons/clock.svg";
import Link from "/public/svg/icons/link.svg";
import Mail from "/public/svg/icons/mail.svg";
import Phone from "/public/svg/icons/phone.svg";
import Play from "/public/svg/icons/play.svg";
import ArrowRight from "/public/svg/icons/arrow-right.svg";
import Filter from "/public/svg/icons/filter.svg";
import Weibo from "/public/svg/icons/weibo.svg";
import Bilibili from "/public/svg/icons/bilibili.svg";
import Zhihu from "/public/svg/icons/zhihu.svg";
import Line from "/public/svg/icons/line.svg";

export const iconTypes = [
  "arrow-diverge",
  "arrow-down",
  "arrow-down-left",
  "arrow-down-right",
  "arrow-up",
  "arrow-up-right",
  "arrow-right",
  "arrow-expand",
  "caret-down",
  "caret-right",
  "caret-left",
  "add",
  "minus",
  "world",
  "search",
  "mobile-nav-button",
  "cancel",
  "check",
  "checkmark",
  "facebook",
  "instagram",
  "linkedin",
  "twitter",
  "youtube",
  "binary",
  "science",
  "factory",
  "presentation",
  "car",
  "custom-3d-cube",
  "custom-vr",
  "computer",
  "ellipsis",
  "eye-tracking",
  "settings",
  "users",
  "game-controller",
  "power",
  "book-open",
  "life-ring",
  "wave",
  "graph-line",
  "ellipsis-filled",
  "quote",
  "hierarchy",
  "user-circle",
  "credit-card",
  "file-badge",
  "file-download",
  "location",
  "chat",
  "wallet",
  "clock",
  "link",
  "mail",
  "phone",
  "play",
  "filter",
  "weibo",
  "bilibili",
  "zhihu",
  "line",
] as const;

export type IconType = (typeof iconTypes)[number];

// missing SVG type from svgr webpackplugin
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const icons: Record<IconType, any> = {
  "arrow-diverge": ArrowDiverge,
  "arrow-down": ArrowDown,
  "arrow-down-left": ArrowDownLeft,
  "arrow-down-right": ArrowDownRight,
  "arrow-expand": ArrowExpand,
  "caret-down": CaretDown,
  "caret-right": CaretRight,
  "caret-left": CaretLeft,
  "arrow-up": ArrowUp,
  "arrow-up-right": ArrowUpRight,
  check: Check,
  "arrow-right": ArrowRight,
  checkmark: Checkmark,
  add: Add,
  minus: Minus,
  world: World,
  search: Search,
  "mobile-nav-button": MobileNavButton,
  cancel: Cancel,
  facebook: FaceBook,
  instagram: Instagram,
  linkedin: LinkedIn,
  twitter: Twitter,
  youtube: YouTube,
  binary: Binary,
  science: Science,
  factory: Factory,
  presentation: Presentation,
  car: Car,
  "custom-3d-cube": Custom3dCube,
  "custom-vr": CustomVR,
  computer: Computer,
  ellipsis: Ellipsis,
  "eye-tracking": EyeTracking,
  settings: Settings,
  users: Users,
  "game-controller": GameController,
  power: Power,
  "book-open": BookOpen,
  "life-ring": LifeRing,
  wave: Wave,
  "graph-line": GraphLine,
  "ellipsis-filled": EllipsisFilled,
  quote: Quote,
  hierarchy: Hierarchy,
  "user-circle": UserCircle,
  "credit-card": CreditCard,
  "file-badge": FileBadge,
  "file-download": FileDownload,
  location: Location,
  chat: Chat,
  wallet: Wallet,
  clock: Clock,
  link: Link,
  mail: Mail,
  phone: Phone,
  play: Play,
  filter: Filter,
  weibo: Weibo,
  bilibili: Bilibili,
  zhihu: Zhihu,
  line: Line,
};

interface Props {
  icon: IconType;
  width?: number | "none";
  noWidth?: boolean;
  color?: string;
  className?: string;
}

const Icon = ({ icon, width = 24, color, className }: Props) => {
  const IconComponent = icons[icon];
  return IconComponent ? (
    <IconComponent
      preserveAspectRatio="xMidYMid"
      style={{
        "--color": color,
        "--width": width === "none" ? "none" : width / 16 + "rem",
      }}
      className={cn(s.icon, className)}
    />
  ) : null;
};

export default Icon;
