import React, { createElement, forwardRef, HTMLAttributes } from "react";
import { useIsExternalLink } from "../../hooks/useIsExternalLink";
import s from "./Button.module.scss";
import cn from "classnames";
import Icon from "../Icon";
import { IconType } from "../Icon/Icon";
import Pill from "../Pill";

export type Variant = "default" | "cta" | "inverted" | "text";

type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
  variant?: Variant;
  size?: "small" | "large";
  disabled?: boolean;
  icon?: IconType;
  href?: string;
  openInNewTab?: boolean;
  pill?: string | number;
  download?: boolean;
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  function Button(
    {
      children,
      variant = "default",
      disabled,
      size = "large",
      href,
      icon,
      pill,
      openInNewTab = false,
      ...props
    },
    ref
  ) {
    const { isExternalLink, shouldNofollow } = useIsExternalLink(href);
    const titleText = typeof children === "string" ? children : undefined;
    return createElement(
      href ? "a" : "button",
      {
        ref,
        href,
        className: cn(s.button, {
          [s.default]: variant === "default",
          [s.cta]: variant === "cta",
          [s.inverted]: variant === "inverted",
          [s.text]: variant === "text",
          [s.disabled]: disabled,
          [s.small]: size === "small",
          [s.withPill]: !!pill,
        }),
        ...props,
        target: openInNewTab || isExternalLink ? "_blank" : undefined,
        title: titleText,
        rel: (function () {
          if (isExternalLink && shouldNofollow) {
            return "noopener noreferrer nofollow";
          } else if (isExternalLink && !shouldNofollow) {
            return "noopener noreferrer";
          } else {
            return "";
          }
        })(),
      },
      <span>
        {children}
        {icon && (
          <Icon
            icon={icon}
            width={size !== "small" || variant === "text" ? 14 : 10}
            className={cn({ [s.offset]: variant === "text" })}
          />
        )}
        {isExternalLink && (
          <Icon icon="arrow-up-right" width={12} className={s.externalIcon} />
        )}
        {pill && <Pill>{pill}</Pill>}
      </span>
    );
  }
);

export default Button;
